<script setup lang="ts">
import { useSeoPath } from '~/composables/useSeoPath';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import { inject, computed } from 'vue';
import { getTranslatedProperty } from '@shopware-pwa/helpers-next';
import type { Schemas } from '#shopware';

const props = withDefaults(
    defineProps<{
        category: Schemas['Category'];
        isTouch?: boolean;
        isActive?: boolean;
        preventNavigation?: boolean;
    }>(),
    {
        isTouch: false,
        isActive: false,
        preventNavigation: false,
    },
);

const isSecured = computed(() => !!props.category?.extensions?.attributes?.loginModal);
const isPrivate = computed(() => !!props.category?.extensions?.attributes?.loggedInRestriction);
const isBold = computed(() => !!props.category?.extensions?.attributes?.isBold);
const { getPath, getSeoPathsForForeignKey } = useSeoPath();
const { convertSeoUrlsToCode } = useLanguageSelect();
const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const { isLoggedIn } = useUser();

const destination = computed(() => {
    if (isSecured.value && !isLoggedIn.value) return undefined;

    if (props.isTouch && props.category.childCount > 0 && !props.isActive) return undefined;

    return getPath(props.category);
});

const onClick = () => {
    if (isSecured.value && !isLoggedIn.value) {
        loginActions();
    }
};

const loginActions = async () => {
    let returnPaths = null;
    const paths = await getSeoPathsForForeignKey(props.category.id);
    if (paths) {
        returnPaths = convertSeoUrlsToCode(paths, false);
    }

    offcanvas.open('AccountLoginForm', 'layout-login-offcanvas', { returnPaths });
};

const isVisible = computed(() => {
    return !isPrivate.value || (isPrivate.value && isLoggedIn.value);
});

const externalLink = getTranslatedProperty(props.category, 'externalLink');
const linkNewTab = getTranslatedProperty(props.category, 'linkNewTab');
</script>

<template>
    <NuxtLink
        v-if="isVisible && !preventNavigation && destination"
        :target="externalLink || linkNewTab ? '_blank' : ''"
        :to="destination"
        :title="getTranslatedProperty(category, 'name')"
        class="cursor-pointer"
        :class="{ 'font-bold': isBold }"
        @click="onClick"
    >
        <slot>{{ getTranslatedProperty(category, 'name') }}</slot>
    </NuxtLink>
    <div
        v-else-if="isVisible"
        role="button"
        :title="getTranslatedProperty(category, 'name')"
        class="cursor-pointer"
        :class="{ 'font-bold': isBold }"
        @click="onClick"
    >
        <slot>{{ getTranslatedProperty(category, 'name') }}</slot>
    </div>
</template>

<style lang="scss" scoped>
.router-link-active {
    @apply text-brand-primary;
}
</style>
